import React from 'react';

import Timeline from 'components/ui/Timeline';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import FormatHtml from 'components/utils/FormatHtml';

export interface EducationsFrontmatter {
    title: string;
    subtitle: string;
    educations: [
        {
            university: string;
            degree: string;
            startDate: string;
            endDate: string;
            bullets: string[];
        }
    ];
}

interface EducationsProps {
    data: {
        frontmatter: EducationsFrontmatter;
    };
}

const Education: React.FC<EducationsProps> = ({ data }) => {
    const { frontmatter } = data;

    const sectionTitle = {
        title: frontmatter.title,
        subtitle: frontmatter.subtitle,
    };
    const { educations } = frontmatter;

    return (
        <Container>
            <TitleSection title={sectionTitle.title} subtitle={sectionTitle.subtitle} />

            {educations.map((item, index) => {
                const { university, degree, startDate, endDate, bullets } = item;

                return (
                    <Timeline
                        key={index}
                        title={university}
                        subtitle={degree}
                        content={
                            <FormatHtml
                                content={
                                    bullets.length > 0
                                        ? `<ul>
											${bullets.map((bullet) => `<li>${bullet}</li>`).join('')}
										</ul>`
                                        : ''
                                }
                            />
                        }
                        startDate={startDate}
                        endDate={endDate}
                    />
                );
            })}
        </Container>
    );
};

export default Education;
