import styled from 'styled-components';
import tw from 'tailwind.macro';
import { motion } from 'framer-motion';

export const Button = motion.custom(styled.button`
    outline: none !important;
    top: 90px;
    ${tw`absolute right-0 mt-4 mr-10 flex items-center justify-center text-sm py-2 px-4 rounded-full border border-gray-800 bg-gray-200 text-gray-800`};

    & .file-pdf-icon {
        margin-right: 8px;
    }
`);
