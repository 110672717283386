import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import SEO, { SeoFrontmatter } from 'components/SEO';
import Experience, { ExperiencesFrontmatter } from 'components/Experience';
import Education, { EducationsFrontmatter } from 'components/Education';
import locales from 'constants/locales';

interface Props {
    pageContext: { locale: keyof typeof locales };
    data: {
        experiences: { frontmatter: ExperiencesFrontmatter };
        educations: { frontmatter: EducationsFrontmatter };
        seo: { frontmatter: SeoFrontmatter };
    };
}

const ResumePage: React.FC<Props> = ({
    pageContext: { locale },
    data: { experiences, educations, seo },
}) => (
    <Layout locale={locale}>
        <SEO
            path="resume"
            title={seo.frontmatter.title}
            description={seo.frontmatter.description}
            locale={seo.frontmatter.locale}
        />
        <Experience data={experiences} />
        <hr />
        <Education data={educations} />
    </Layout>
);

export const query = graphql`
    query ResumeQuery($locale: String!) {
        seo: markdownRemark(
            frontmatter: { category: { eq: "seo-resume" }, locale: { eq: $locale } }
        ) {
            frontmatter {
                title
                description
                locale
            }
        }
        experiences: markdownRemark(
            frontmatter: { category: { eq: "experiences" }, locale: { eq: $locale } }
        ) {
            frontmatter {
                title
                subtitle
                viewPDFLabel
                experiences {
                    company
                    position
                    startDate
                    endDate
                    bullets
                }
            }
        }
        educations: markdownRemark(
            frontmatter: { category: { eq: "educations" }, locale: { eq: $locale } }
        ) {
            frontmatter {
                title
                subtitle
                educations {
                    university
                    degree
                    startDate
                    endDate
                    bullets
                }
            }
        }
    }
`;

export default ResumePage;
