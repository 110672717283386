/* eslint-disable unicorn/consistent-destructuring */
import React from 'react';

import Timeline from 'components/ui/Timeline';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import FormatHtml from 'components/utils/FormatHtml';

import Icon from 'components/ui/Icon';
import resumeFile from '../../assets/files/tasos_kakouris_resume.pdf';

import * as Styles from './styles';

export interface ExperiencesFrontmatter {
    title: string;
    subtitle: string;
    viewPDFLabel: string;
    experiences: [
        {
            company: string;
            position: string;
            startDate: string;
            endDate: string;
            bullets: string[];
        }
    ];
}

interface ExperiencesProps {
    data: {
        frontmatter: ExperiencesFrontmatter;
    };
}

const Experience: React.FC<ExperiencesProps> = ({ data }) => {
    const { frontmatter } = data;

    const sectionTitle = {
        title: frontmatter.title,
        subtitle: frontmatter.subtitle,
    };
    const { experiences } = frontmatter;

    return (
        <Container>
            <a href={resumeFile} rel="noreferrer noopener" target="_blank">
                <Styles.Button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                    <Icon icon="file-pdf" className="file-pdf-icon" />
                    {frontmatter.viewPDFLabel}
                </Styles.Button>
            </a>

            <TitleSection title={sectionTitle.title} subtitle={sectionTitle.subtitle} />

            {experiences.map((item, index) => {
                const { company, position, startDate, endDate, bullets = [] } = item;

                return (
                    <Timeline
                        key={index}
                        title={company}
                        subtitle={position}
                        content={
                            <FormatHtml
                                content={
                                    bullets.length > 0
                                        ? `<ul>
											${bullets.map((bullet) => `<li>${bullet}</li>`).join('')}
										</ul>`
                                        : ''
                                }
                            />
                        }
                        startDate={startDate}
                        endDate={endDate}
                    />
                );
            })}
        </Container>
    );
};

export default Experience;
